import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const ImageList = ({ images }) => {
  const data = useStaticQuery(graphql`
    query ImageUrlQuery {
      site {
        siteMetadata {
          awsImageUrl
        }
      }
    }
  `);

  return (
    <div>
      {images.map(img => {
        if (['cr3', 'mp4'].includes(img.slice(-3).toLowerCase())) {
          return (<p><a key={img} href={`${data.site.siteMetadata.awsImageUrl}/${img}`}>{img}</a></p>);
        } else {
          return (<p><img key={img} alt="" src={`${data.site.siteMetadata.awsImageUrl}/${img}`} /></p>);
        }
        
      })}
    </div>
  );
};

export default ImageList;