import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import ImageList from "../components/images/ImageList";

const TFPImages = ({ data }) => {
  const tfp = data.allTfpImagesYaml.edges[0].node;

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <h1>{tfp.accountName}</h1>
      <p>
        To Download, right-click and select "Save image As..." or "Open image in new tab"
      </p>
      <ImageList images={tfp.images || []} />
      
      <Link to="/">Go back to the homepage</Link>
      
    </Layout>
  );
};

export default TFPImages;

export const query = graphql`
  query TFPImagesTemplateQuery($id: String!) {
    allTfpImagesYaml(filter: { id: { eq: $id }}) {
      edges {
        node {
          accountName
          images
        }
      }
    }
  }
`;